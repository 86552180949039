import ActivityTypeSelect from '../ActivityTypeSelect';
import CreatableInputOnly from '../../../common/AtsSelect/CreatableInputOnly';
import AtsSelect from '../../../common/AtsSelect';
import JobFunctionsSkillsSelect from '../../../common/Categories/JobFunctionsSkillsSelect';
import AtsTypeAheadSearchSelect from '../../../common/AtsTypeAheadSearchSelect';
import AtsDatePicker from '../../../common/AtsDatePicker';

import searchConst from '../../../../constants/searchConst';
import stateMap from '../../../../constants/stateMap';

const includeAll = searchConst.searchOptions.includeAll;
const includeAny = searchConst.searchOptions.includeAny;
const exclude = searchConst.searchOptions.exclude;
const withValue = searchConst.searchOptions.withValue;
const withoutValue = searchConst.searchOptions.withoutValue;
// TODO: Remove comments after Sprint 36 closes
// const between = searchConst.searchOptions.date.filter(obj => obj.id === searchConst.between);

const Placement = [
  {
    value: 'accountCoordinatorUserIds',
    label: 'Account Coordinator',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'recruiters',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'accountManagerUserIds',
    label: 'Account Manager',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'recruiters',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'activity',
    label: 'Activity',
    searchOptions: [searchConst.searchOptions.includeAnyActivity, searchConst.searchOptions.includeAllActivity, searchConst.searchOptions.excludeActivity, searchConst.searchOptions.activityDefaultValue],
    searchOptionComponent: ActivityTypeSelect
  },
  {
    value: 'assignedToUserIds',
    label: 'Assigned To',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: ['users', 'teams'],
    dropdownOptionsMapTo: ['assignedToUserIds', 'assignedToTeamIds'],
    searchOptionComponent: AtsSelect
  },
  {
    value: 'badgeIds',
    label: 'Badges',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'badges',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'backOfficeOwnerUserIds',
    label: 'Back Office Owner',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'recruiters',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'contractorEngagementUserIds',
    label: 'Contractor Engagement',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'recruiters',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'billingContactIds',
    label: 'Billing Contact',
    searchOptions:[includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: AtsTypeAheadSearchSelect,
    entityType: 'CONTACT',
    clearButton: false
  },
  {
    value: 'billingSchedule',
    label: 'Billing Schedule',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'placementBillingSchedules',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'bullhornIds',
    label: 'Bullhorn ID',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'businessUnitIds',
    label: 'Business Units',
    searchOptions: [includeAny, includeAll, withValue],
    dropdownOptionsLookupId: 'businessUnits',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'candidateIds',
    label: 'Candidate',
    searchOptions:[includeAny, exclude],
    entityType: 'CANDIDATE',
    searchOptionComponent: AtsTypeAheadSearchSelect,
    clearButton: false
  },
  {
    value: 'phcCareerCodeIds',
    label: 'Career Codes',
    searchOptions: [includeAny, includeAll, exclude],
    dropdownOptionsLookupId: 'phcCareerCodes',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'companyIds',
    label: 'Company',
    searchOptions: [includeAny, exclude],
    searchOptionComponent: AtsTypeAheadSearchSelect,
    entityType: 'COMPANY',
    clearButton: false
  },
  {
    value: 'costCenter',
    label: 'Cost Center',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'currencyType',
    label: 'Currency',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'currencyTypes',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'dateCreatedRange',
    label: 'Date Added',
    searchOptions: searchConst.searchOptions.date.concat([searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar]),
    searchOptionComponent: AtsDatePicker
  },
  {
    value: 'dateUpdatedRange',
    label: 'Date Last Updated',
    searchOptions: searchConst.searchOptions.date.concat([searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar]),
    searchOptionComponent: AtsDatePicker
  },
  {
    value: 'departmentIds',
    label: 'Departments',
    searchOptions: [includeAny, includeAll, exclude],
    dropdownOptionsLookupId: 'departments',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'division',
    label: 'Division',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'placementDivisions',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'supplementalDocumentsKeywords',
    label: 'Documents',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'equipmentAllocated',
    label: 'Equipment Allocated',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'placementEquipmentAllocates',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'equipmentReturn',
    label: 'Equipment Return',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'placementEquipmentReturns',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'scheduledEndDate',
    label: 'End Date',
    searchOptions: searchConst.searchOptions.date.concat(
      [ searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheNext,
        searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar, searchConst.searchOptions.inTheNextCalendar
      ]
    ),
    searchOptionComponent: AtsDatePicker,
    dateOnly: true
  },
  {
    value: 'phcFacilityTypeIds',
    label: 'Facility Types',
    searchOptions: [includeAny, includeAll, exclude],
    dropdownOptionsLookupId: 'phcFacilityTypes',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'glAccount',
    label: 'GL Account#',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'highlightedSkills',
    label: 'Highlighted Skills',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'hireType',
    label: 'Hire Type',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'placementHireTypes',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'industryIds',
    label: 'Industries',
    searchOptions: [includeAny, includeAll, exclude],
    dropdownOptionsLookupId: 'industries',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'jobFunctionIds',
    label: 'Job Functions',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'jobFunctions',
    searchOptionComponent: JobFunctionsSkillsSelect
  },
  {
    value: 'effectiveDate',
    label: 'Last Effective Date',
    searchOptions: searchConst.searchOptions.date.concat(
      [ searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheNext,
        searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar, searchConst.searchOptions.inTheNextCalendar
      ]
    ),
    searchOptionComponent: AtsDatePicker,
    dateOnly: true
  },
  {
    value: 'listIds',
    label: 'Lists',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: AtsTypeAheadSearchSelect,
    entityType: 'PLACEMENT',
    clearButton: false,
    maxLengthToApi: searchConst.minLength, // typeAhead min input length, send to api, then filter in the frontEnd
    delay: 0,
    searchOperation:'listsSearchMy',
    filterBy: ['name']
  },
  {
    value: 'jobTitle',
    label: 'Placement Title',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'originalStartDate',
    label: 'Original Start Date',
    searchOptions: searchConst.searchOptions.date.concat(
      [ searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheNext,
        searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar, searchConst.searchOptions.inTheNextCalendar
      ]
    ),
    searchOptionComponent: AtsDatePicker,
    dateOnly: true
  },
  {
    value: 'overtimeType',
    label: 'Overtime Eligibility',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'placementOvertimeTypes',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'patientCareType',
    label: 'Patient Care',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'placementPatientCareTypes',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'placementType',
    label: 'Placement Type',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'placementTypes',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'placementIds',
    label: 'Placement Id',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'purchaseOrder',
    label: 'Purchase Order#',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'recruiterUserIds',
    label: 'Recruiter',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: ['recruiters', 'teams'],
    dropdownOptionsMapTo: ['recruiterUserIds', 'recruiterTeamIds'],
    searchOptionComponent: AtsSelect
  },
  {
    value: 'referredBy',
    label: 'Referred By',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'salespersonUserIds',
    label: 'Salesperson',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: ['users', 'teams'],
    dropdownOptionsMapTo: ['salespersonUserIds', 'salespersonTeamIds'],
    searchOptionComponent: AtsSelect
  },
  // {
  //   value: 'skillIds',
  //   label: 'Skills',
  //   searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
  //   dropdownOptionsLookupId: 'skills',
  //   searchOptionComponent: JobFunctionsSkillsSelect
  // },
  {
    value: 'sourceOptionIds',
    label: 'Source',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'sourceOptions',
    orderBy: 'id',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'scheduledStartDate',
    label: 'Start Date',
    searchOptions: searchConst.searchOptions.date.concat(
      [ searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheNext,
        searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar, searchConst.searchOptions.inTheNextCalendar
      ]
    ),
    searchOptionComponent: AtsDatePicker,
    dateOnly: true
  },
  {
    value: 'status',
    label: 'Status',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'placementStatuses',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'closeReason',
    label: 'Close Reason',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: ['placementCloseReasons', 'placementNoStartReasons', 'placementTerminationReasons'],
    searchOptionComponent: AtsSelect
  },
  {
    value: 'stage',
    label: 'Stage',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'placementStages',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'taxCity',
    label: 'Tax City',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'taxState',
    label: 'Tax State',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    dropdownOptions: stateMap.map(state => { return {...state, id: state.value, name: state.label}; }),
    searchOptionComponent: AtsSelect
  },
  {
    value: 'vmsJobIds',
    label: 'VMS ID',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly,
    props: {
      placeholder: 'Type words for search and press enter...',
    }
  },
  {
    value: 'vmsPlacementIds',
    label: 'VMS Placement ID',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly,
    props: {
      placeholder: 'Type words for search and press enter...',
    }
  },
  {
    value: 'vmsName',
    label: 'VMS Name',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly,
    props: {
      placeholder: 'Type words for search and press enter...',
    }
  },
  // TODO: Remove comments after Sprint 36 closes
  // {
  //   value: 'activeDate',
  //   label: 'Active Date',
  //   searchOptions: between,
  //   searchOptionComponent: AtsDatePicker,
  //   dateOnly: true
  // },
];

export default Placement;
